import React from 'react';
import PropTypes from 'prop-types';
import InputText from './InputText';
import { formatDateAPI, formatDateHTML } from '../../lib/date';

/**
 * @fero
 */

class DatePicker extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        placeholder: PropTypes.string,
    };

    handleChange = (newVal) => {
        const {onChange} = this.props;
        if(onChange != null)
            onChange(formatDateAPI(newVal));
    }

    render() {
        const {value, onChange, min, max, ...props} = this.props;
        return <InputText
            type="date"
            value={formatDateHTML(value)}
            onChange={this.handleChange}
            min={formatDateHTML(min)}
            max={formatDateHTML(max)}
            changeImmediately={true}
            {...props}
        />;
    }

}

export default DatePicker;